import * as React from "react";
import { useContext } from "react";
import { Link as GatsbyLink } from "gatsby";
import styled from "@emotion/styled";

import theme, { Size, Type, ThemeContext, Weight } from "utils/theme";

const { font: { base: { lineHeight, size } }, spacing: { base, small } } = theme;

type Display = "inline" | "inline-block";

interface StyledLinkProps {
  color: string;
  hover: string;
  lineHeight: number;
  size: number;
  weight: number;
}

const StyledLink = styled(GatsbyLink)<StyledLinkProps>`
  color: ${({ color }) => color};
  display: inline;
  line-height: ${({ lineHeight }) => lineHeight}px;
  font-size: ${({ size }) => size}px;
  font-weight: ${({ weight }) => weight};

  &:hover {
    color: ${({ hover }) => hover};
  }
`;

interface LinkProps {
  className?: string;
  fontSize?: Size;
  fontWeight?: Weight;
  to: string;
  type?: Type;
}

const Link: React.FC<LinkProps> = ({ children, className, fontSize = "base", fontWeight = 400, to, type = "primary" }) => {
  const themeContext = useContext(ThemeContext);
  const { link } = theme[themeContext];
  const { lineHeight, size } = theme.font[fontSize];
  const { color, hover } = link[type];

  return (
    <StyledLink
      color={color}
      hover={hover}
      lineHeight={lineHeight}
      size={size}
      to={to}
      weight={fontWeight}
      className={className}
    >
      {children}
    </StyledLink>
  );
};

export default Link;
