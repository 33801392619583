import { createContext } from "react";

const { black, cyan, gray, white } = {
  black: "#0b0033",
  cyan: "#43ddf0",
  gray: "#8d8d8d",
  white: "#fcfcfc",
};

const theme = {
  font: {
    family: "Libre Franklin",
    base: {
      lineHeight: 24,
      size: 18,
    },
    large: {
      lineHeight: 38,
      size: 24,
    }
  },
  spacing: {
    base: 18,
    small: 12,
  },
  dark: {
    background: black,
    button: {
      alternate: {
        background: black,
        border: white,
        color: white,
      },
      primary: {
        background: white,
        border: black,
        color: black,
      },
    },
    font: {
      alternate: gray,
      primary: white,
    },
    link: {
      alternate: {
        color: gray,
        hover: white,
      },
      primary: {
        color: cyan,
        hover: cyan,
      },
    }
  },
  light: {
    background: white,
    button: {
      alternate: {
        background: black,
        border: white,
        color: white,
      },
      primary: {
        background: white,
        border: black,
        color: black,
      },
    },
    font: {
      alternate: gray,
      primary: black,
    },
    link: {
      alternate: {
        color: white,
        hover: cyan,
      },
      primary: {
        color: white,
        hover: cyan,
      },
    }
  }
};

export type Size = "base" | "large";
export type Theme = "dark" | "light";
export type Type = "alternate" | "primary";
export type Weight = 400 | 600 | 700 | 800;

export const ThemeContext = createContext<"dark" | "light">("dark");

export default theme;
