import * as React from "react";
import styled from "@emotion/styled";

import Link from "components/Link";
import LinkButton from "components/LinkButton";
import theme from "utils/theme";

const { spacing: { base, small } } = theme;

const NavContainer = styled.nav`
  align-items: center;
  display: flex;
`;

const NavLink = styled(Link)`
  margin-right: 80px;
  line-height: 72px;
  padding: ${small}px ${base}px;
  text-decoration: none;
`;

const Nav = () => {
  const type = "alternate";

  return (
    <NavContainer>
      <NavLink to="#" type={type}>How it works</NavLink>
      <NavLink to="#" type={type}>Consulting</NavLink>
      <NavLink to="#" type={type}>Why Beta</NavLink>
      <LinkButton to="#" type={type}>Schedule a call</LinkButton>
    </NavContainer>
  )
};

export default Nav;
