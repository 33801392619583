import * as React from "react";
import { useContext } from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";

import theme, { Type, ThemeContext } from "utils/theme";

const { font: { base: { lineHeight, size } }, spacing: { base, small } } = theme;

interface StyledLinkProps {
  background: string;
  border: string;
  color: string;
}

const StyledLink = styled(Link)<StyledLinkProps>`
  background: ${({ background }) => background};
  border: 1px solid ${({ border }) => border};
  border-radius: 4px;
  color: ${({ color }) => color};
  display: inline-block;
  font-size: ${size}px;
  font-weight: 800;
  line-height: ${lineHeight}px;
  padding: ${small}px ${base}px;
  text-decoration: none;
`;

interface LinkButtonProps {
  to: string;
  type: Type;
}

const LinkButton: React.FC<LinkButtonProps> = ({ children, to, type = "primary" }) => {
  const themeContext = useContext(ThemeContext);
  const { button } = theme[themeContext];
  const { background, border, color } = button[type];

  return (
    <StyledLink background={background} border={border} color={color} to={to}>{children}</StyledLink>
  );
};

export default LinkButton;
