import * as React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled";

import Logo from "images/logo.svg";
import theme, { ThemeContext } from "utils/theme";
import Nav from "components/Nav";

const HeaderContainer = styled.header`
  align-items: stretch;
  background: ${theme.dark.background};
  display: flex;
  height: 96px;
  justify-content: space-between;
  padding: 0 32px;
`;

const LogoContainer = styled.div`
  align-items: stretch;
  display: flex;
`;

const StyledH1 = styled.h1`
  align-items: stretch;
  display: flex;
  margin: 0;
`;

const StyledLink = styled(Link)`
  align-items: center;
  display: flex;
`;

const Header = () => (
  <ThemeContext.Provider value="dark">
    <HeaderContainer>
      <LogoContainer>
        <StyledH1>
          <StyledLink to="/" >
            <Logo />
          </StyledLink>
        </StyledH1>
      </LogoContainer>
      <Nav />
    </HeaderContainer>
  </ThemeContext.Provider>
)

export default Header
